var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"home"},[_c('div',{staticClass:"left_f fl"},[(_vm.isMoblie)?_c('div',{staticClass:"menu1"},[_vm._m(0),_vm._m(1)]):_vm._e(),(!_vm.isMoblie)?_c('div',{staticClass:"menu1"},[_c('a',{attrs:{"href":"http://company.zssgdsb-85176920tsgjnz.com/about.html","target":"_blank"}},[_vm._v("关于我们")]),_vm._v("| "),_c('a',{attrs:{"href":"http://company.zssgdsb-85176920tsgjnz.com/cpyc.html#/recruit","target":"_blank"}},[_vm._v("诚聘英才")]),_vm._v("| "),_c('a',{attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/us/wangzsm.aspx","target":"_blank"}},[_vm._v("网站声明")]),_vm._v("| "),_c('a',{attrs:{"href":"http://company.zssgdsb-85176920tsgjnz.com/lxwm.html#/contact","target":"_blank"}},[_vm._v("联系我们")]),_vm._v("| "),_c('a',{attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserSuggestAdd1.aspx","target":"_blank"}},[_vm._v("意见和建议")]),_vm._v("| "),_c('a',{attrs:{"href":"mailto:chiefeditor@zssgdsb-85176920tsgjnz.com"}},[_vm._v("总监信箱")])]):_vm._e(),_vm._m(2),_c('div',{staticClass:"footer_bottom"},[_vm._v(" Copyright©1998-"+_vm._s(_vm.newYear)+" DRCnet. All Rights Reserved 版权所有 国研网 ")])]),(!_vm.isMoblie)?_c('div',{staticClass:"right_f_wx fr"},[_vm._m(3)]):_vm._e(),_c('div',{staticClass:"clear"})]),_c('div',{staticClass:"clear"}),(_vm.isMoblie)?_c('div',{staticClass:"bottom-f"},[_vm._m(4)]):_vm._e(),(!_vm.isMoblie)?_c('div',{staticClass:"bottom-f"},[_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"href":"http://company.zssgdsb-85176920tsgjnz.com/about.html","target":"_blank"}},[_vm._v("关于我们")]),_vm._v("| "),_c('a',{attrs:{"href":"http://company.zssgdsb-85176920tsgjnz.com/cpyc.html#/recruit","target":"_blank"}},[_vm._v("诚聘英才")]),_vm._v("| "),_c('a',{attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/us/wangzsm.aspx","target":"_blank"}},[_vm._v("网站声明")]),_vm._v("| "),_c('a',{attrs:{"href":"http://company.zssgdsb-85176920tsgjnz.com/lxwm.html#/contact","target":"_blank"}},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"10px"}},[_c('a',{attrs:{"href":"http://misc.zssgdsb-85176920tsgjnz.com/DRCNet.Users.Web/User/UserSuggestAdd1.aspx","target":"_blank"}},[_vm._v("意见和建议")]),_vm._v("| "),_c('a',{attrs:{"href":"mailto:chiefeditor@zssgdsb-85176920tsgjnz.com"}},[_vm._v("总监信箱")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tel"},[_c('img',{staticClass:"fl",attrs:{"src":"http://www.zssgdsb-85176920tsgjnz.com/images/tel.png"}}),_c('div',{staticClass:"hm fl"},[_c('div',{staticClass:"hmd"},[_c('span',[_vm._v("热线电话：")]),_c('p',[_vm._v("400-7070-678")])])]),_c('div',{staticClass:"clear"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"ewm"},[_c('li',[_c('img',{staticClass:"ewm1",attrs:{"src":"https://www.zssgdsb-85176920tsgjnz.com/images/weixin.jpg"}}),_c('p',[_vm._v("服务号")])]),_c('li',[_c('img',{staticClass:"ewm1",attrs:{"src":"https://www.zssgdsb-85176920tsgjnz.com/images/jjzh.jpg"}}),_c('p',[_vm._v("订阅号")])]),_c('li',[_c('img',{staticClass:"ewm1",attrs:{"src":"https://www.zssgdsb-85176920tsgjnz.com/images/drcnet-app-andriod-qrcode.jpg"}}),_c('p',[_vm._v("安卓版")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('span',[_c('a',{staticClass:"ipc",attrs:{"href":"https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg1","target":"_blank"}},[_vm._v("增值电信业务经营许可证 京ICP证030059号    ")])]),_c('span',[_c('a',{staticClass:"ipc",attrs:{"href":"https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg2","target":"_blank"}},[_vm._v("增值电信业务经营许可证 京B2-20070136    ")])]),_c('span',[_c('a',{staticClass:"ipc",attrs:{"href":"https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg3","target":"_blank"}},[_vm._v("出版物经营许可证 新出发京批字第直210398号    ")])]),_c('span',[_vm._v("京公网安备 11010102002958号")]),_c('a',{attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958","target":"_blank"}},[_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958","target":"_blank"}},[_c('img',{staticStyle:{"vertical-align":"middle","padding-left":"5px"},attrs:{"src":require("../../assets/footerImage/beians.png")}})])]),_c('span',{staticStyle:{"padding-left":"10px"}},[_vm._v("建议浏览器：火狐、谷歌")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('ul',{staticClass:"bq"},[_c('li',{staticClass:"tips"},[_c('span',{staticClass:"fl"},[_c('a',{staticClass:"ipc",attrs:{"href":"https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg1","target":"_blank"}},[_vm._v("增值电信业务经营许可证 京ICP证030059号    ")])]),_c('div',{staticClass:"clear"})]),_c('li',{staticClass:"tips"},[_c('span',{staticClass:"fl"},[_c('a',{staticClass:"ipc",attrs:{"href":"https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg2","target":"_blank"}},[_vm._v("增值电信业务经营许可证 京B2-20070136    ")])]),_c('div',{staticClass:"clear"})]),_c('li',{staticClass:"tips"},[_c('span',{staticClass:"fl"},[_c('a',{staticClass:"ipc",attrs:{"href":"https://www.zssgdsb-85176920tsgjnz.com/announcement/zizhiimg3","target":"_blank"}},[_vm._v("出版物经营许可证 新出发京批字第直210398号    ")])]),_c('div',{staticClass:"clear"})]),_c('li',{staticClass:"tips"},[_c('span',{staticClass:"fl"},[_vm._v("京公网安备 11010102002958号")]),_c('a',{staticClass:"fl",attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958","target":"_blank"}},[_c('a',{staticClass:"fl",attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002958","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/footerImage/beians.png")}})])]),_c('div',{staticClass:"clear"})]),_c('li',{staticClass:"tips",staticStyle:{"float":"right","line-height":"41px"}},[_c('span',[_vm._v("建议浏览器：火狐、谷歌")]),_c('div',{staticClass:"clear"})])]),_c('div',{staticClass:"clear"})])
}]

export { render, staticRenderFns }